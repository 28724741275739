@use '@angular/material' as mat;
@import "@fortawesome/fontawesome-free/css/all.css";
@import "styles/variables";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
//@import "~bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sevent-primary: mat.define-palette(mat.$teal-palette);
$sevent-accent: mat.define-palette(mat.$orange-palette, A100, A200, A400);

// The warn palette is optional (defaults to red).
$sevent-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$sevent-theme: mat.define-light-theme($sevent-primary, $sevent-accent, $sevent-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sevent-theme);

/* You can add global styles to this file, and also import other style files */


@import 'styles/mixins/mixins';

$primary: mat.get-color-from-palette($sevent-primary);
$accent: mat.get-color-from-palette($sevent-accent);
$warn: mat.get-color-from-palette($sevent-warn);
@include defaults();
