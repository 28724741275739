@mixin defaults() {
  html, body {
    height: 100%;
  }
  body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
    background: #000000;
    opacity: 0.75 !important;
  }
  .main-wrapper {
    padding: 20px 50px 50px 20px;
    @media (max-width: 599px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  a {
    //color: white;
    text-decoration: none;

    &:visited {

    }
  }
  .component-toolbar {
    button {
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  // Braintree overrides
  [data-braintree-id="paypal"] {
    border: 0px solid;
  }
  [data-braintree-id="paypal-sheet-header"] {
    display: none;
  }
  [data-braintree-id="methods-label"] {
    display: none;
  }
  [data-braintree-id="methods"] {
    padding: 0 1.5rem;
  }
  .error-snackbar {
    background-color: $warn;
  }
  .info-snackbar {
    background-color: $primary;
  }
  .mat-snack-bar-container {
    padding: 8px !important;
  }
  .pin-edit-snackbar {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
  }

  .mat-list-base .mat-list-item.is-active .mat-list-text h4{
    font-weight: bold !important;
  }
  .mat-list-base .mat-list-item .mat-line {
    overflow: visible;
    white-space: unset;
  }

}
